const {
  WebTracerProvider,
  BatchSpanProcessor,
  TraceIdRatioBasedSampler,
} = require('@opentelemetry/sdk-trace-web');
const { Resource } = require('@opentelemetry/resources');
const { registerInstrumentations } = require('@opentelemetry/instrumentation');
const {
  FetchInstrumentation,
} = require('@opentelemetry/instrumentation-fetch');
const {
  XMLHttpRequestInstrumentation,
} = require('@opentelemetry/instrumentation-xml-http-request');
const {
  OTLPTraceExporter,
} = require('@opentelemetry/exporter-trace-otlp-http');
const {
  B3Propagator,
  B3InjectEncoding,
} = require('@opentelemetry/propagator-b3');
const { SpanKind, context, trace } = require('@opentelemetry/api');

const {
  BROWSER_STATE,
  IGNORE_URLS,
  DISTRIBUTED_TRACING,
} = require('./browser');

const TRACE_PARENT_HEADER = 'traceparent';

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const spanConfig = (attributes) => ({
  kind: SpanKind.CLIENT,
  attributes,
});

const sendCustomSpan = (method, spanName, attributes) => {
  // Replaced with real values on build time
  const tracer = trace.getTracer(
    '__opentelemetryBrowserName__',
    '__opentelemetryBrowserVersion__',
  );

  tracer.startActiveSpan(
    `${spanName} ${method}`,
    spanConfig(attributes),
    context.active(),
    (span) => {
      span.end();
    },
  );
};

const getTraceparentMetaContent = () => {
  const metaElement = Array.from(
    // eslint-disable-next-line no-undef
    document.getElementsByTagName('meta') || [],
  ).find((e) => e.getAttribute('name') === TRACE_PARENT_HEADER);

  if (!metaElement?.content) {
    return {};
  }

  const [version, traceId, spanId, sampleDecision] =
    metaElement.content.split('-');

  const attributes = {};

  if (version) {
    attributes[`${TRACE_PARENT_HEADER}.version`] = version;
  }

  if (traceId) {
    attributes[`${TRACE_PARENT_HEADER}.trace_id`] = traceId;
  }

  if (spanId) {
    attributes[`${TRACE_PARENT_HEADER}.span_id`] = spanId;
  }

  if (sampleDecision) {
    attributes[`${TRACE_PARENT_HEADER}.sampled`] = sampleDecision;
  }

  return attributes;
};

const openTelemetryBrowserAgent = () => {
  // eslint-disable-next-line no-undef
  const attributes = window?.[BROWSER_STATE] || null;

  if (!attributes || !isValidUrl(attributes.endpoint)) {
    return null;
  }

  const tracerProvider = new WebTracerProvider({
    sampler: new TraceIdRatioBasedSampler(
      attributes?.features?.[DISTRIBUTED_TRACING].sampling || 0,
    ),
    resource: new Resource({
      'service.name': `${attributes.scope}.${attributes.application}`,
      'x-meli-trace-bu': `${attributes.platform}`,
      'x-meli-trace-site': `${attributes.site}`,
      'x-meli-trace-platform': `${attributes.device}`,
      'fury.application': `${attributes.application}`,
      'fury.service': `${attributes.scope}`,
      d2id: `${attributes.d2id}`,
      ...getTraceparentMetaContent(),
    }),
  });

  const url = `${attributes.endpoint}/v1/traces`;
  const headers = attributes.key ? { 'api-key': attributes.key } : {};

  tracerProvider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url,
        headers,
      }),
    ),
  );

  tracerProvider.register({
    propagator: new B3Propagator({
      injectEncoding: B3InjectEncoding.MULTI_HEADER,
    }),
  });

  registerInstrumentations({
    tracerProvider,
    instrumentations: [
      new FetchInstrumentation({ ignoreUrls: IGNORE_URLS }),
      new XMLHttpRequestInstrumentation({ ignoreUrls: IGNORE_URLS }),
    ],
  });

  // eslint-disable-next-line no-undef
  window.meli_otel = {
    sendCustomSpan,
    agent: '__opentelemetryBrowserName__',
    version: '__opentelemetryBrowserVersion__',
  };

  return sendCustomSpan;
};

module.exports = openTelemetryBrowserAgent;
